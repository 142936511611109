import React, { useEffect, useRef } from "react"
import { usePdf } from "@mikecousins/react-pdf"
import "./style.scss"
import { Container, Row, Col, Modal } from "react-bootstrap"
import { InlineShareButtons } from "sharethis-reactjs"
import Button, { IconButton } from "../Button"

const RenderYouTubeVideo = ({ id }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`//www.youtube.com/embed/${id}`}
        frameBorder="0"
        title={id}
      />
    </div>
  )
}

const RenderVimeoVideo = ({ vimeourl }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        title={`PopUp for ${vimeourl}`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://player.vimeo.com/video/${vimeourl}?color=ffffff&title=0&byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  )
}

const RenderPDF = ({ pdf }) => {
  const canvasEl = useRef(null)

  const [loading] = usePdf({
    file: pdf,
    canvasEl,
  })

  return (
    <>
      {loading && <span>Loading...</span>}
      <canvas ref={canvasEl} />
    </>
  )
}

export default props => {
  useEffect(() => {
    // eslint-disable-line no-restricted-globals
    window.onpopstate = event => {
      if (history.state) { // eslint-disable-line no-restricted-globals
        // eslint-disable-line no-restricted-globals
        props.onHide();
      }
    }
  }, [props]);

  return (
    <Modal {...props} dialogClassName={"m0 w-100"} aria-labelledby="modal">
      <Modal.Header>
        <Container className="pr-0">
          <div className={"modal-head"}>
            <div>
              <span className="type-text">{props.pdf ? `PDF` : `Video`}</span>
              <Modal.Title className="type-title" id="modal">
                {props.title}
              </Modal.Title>
            </div>
            <div className={"reverse-col-m"}>
              {props.pdf ? (
                <Button
                  href={props.pdf}
                  target={"_blank"}
                  icon={"arrow-down"}
                  label={"Download"}
                  className="btn-bg-blue"
                />
              ) : (
                <>
                  <InlineShareButtons
                    data-network="sharethis"
                    data-url={
                      props.video
                        ? `//www.youtube.com/watch?v=${props.video}`
                        : `${props.vimeourl}`
                    }
                    className="st-custom-button"
                    config={{
                      alignment: "right", // alignment of buttons (left, center, right)
                      color: "social", // set the color of buttons (social, white)
                      enabled: true, // show/hide buttons (true, false)
                      font_size: 14, // font size for the buttons
                      labels: "cta", // button labels (cta, counts, null)
                      language: "en", // which language to use (see LANGUAGES)
                      networks: [
                        // which networks to include (see SHARING NETWORKS)
                        "sharethis",
                      ],
                      padding: 12, // padding within buttons (INTEGER)
                      radius: 21, // the corner radius on each button (INTEGER)
                      size: 45, // the size of each button (INTEGER)

                      // OPTIONAL PARAMETERS
                      url: `//www.youtube.com/watch?v=${props.video}`, // (defaults to current url)
                    }}
                  />
                  <div
                    data-network="sharethis"
                    className="button share-button sharethis-share-button st-custom-button"
                    data-url={
                      props.video
                        ? `//www.youtube.com/watch?v=${props.video}`
                        : `${props.vimeourl}`
                    }
                  >
                    Share Video
                  </div>
                </>
              )}
              <IconButton
                icon={"times"}
                className="icon-style"
                onClick={() => props.onHide()}
              />
            </div>
          </div>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container className={"text-center"}>
          <Row className="show-grid">
            <Col>
              {props.video && <RenderYouTubeVideo id={props.video} />}
              {props.pdf && <RenderPDF pdf={props.pdf} />}
              {props.vimeourl && <RenderVimeoVideo vimeourl={props.vimeourl} />}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
