import React, { useState } from "react"
import { Card, Container, Row, Col } from "react-bootstrap"
import "./style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Popup from "./../PopUp"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import Img from "gatsby-image"

export default ({
  title,
  caption,
  pdf = null,
  video = null,
  imageAlt = "Missing Alt Text",
  className,
  isFeatured = false,
  field_vimeo_id = null,
  link,
  relationships,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const imageThumb =
    relationships?.field_image?.relationships?.field_media_image?.thumbnail
  const imageFeatured =
    relationships?.field_image?.relationships?.field_media_image?.featuredImage
  let classes = ""
  classes = field_vimeo_id ? "" : "downloadable"
  classes = className ? ` ${className}` : ""

  let label = field_vimeo_id ? "Watch Video" : "Download"
  let defaultClasses = `card resource`

  const modalPopUp = flag => setModalShow(flag)

  let args = {}

  if (field_vimeo_id) {
    args.onClick = () => modalPopUp(true)
  } else {
    args.href = link?.uri
    args.target = "_blank"
  }

  const TiledCard = ({ classes }) => (
    <>
      <Popup
        show={modalShow}
        onHide={() => modalPopUp(false)}
        title={title}
        video={video}
        pdf={(link && link.uri) || null}
        vimeourl={field_vimeo_id}
      />
      <a
        key={
          imageThumb?.childImageSharp
            ? `link-${imageThumb?.childImageSharp?.fixed?.originalName}`
            : null
        }
        className={classes ? `${defaultClasses} ${classes}` : defaultClasses}
        {...args}
      >
        <div className="resource-card-wrapper">
          <Img
            key={
              "img-thumb-" + imageThumb?.childImageSharp?.fixed?.originalName
            }
            loading={"lazy"}
            fixed={imageThumb?.childImageSharp.fixed}
            alt={imageAlt}
          />
          {video || field_vimeo_id ? (
            <FontAwesomeIcon className="play-icon" icon={faPlay} />
          ) : (
            ""
          )}
        </div>
        <Container className={"labels"} fluid>
          <Row className={"pt-3 px-3 align-items-center"}>
            <Col>
              <Card.Text className={"resource-type"}>
                {field_vimeo_id ? "Video" : "PDF"}
              </Card.Text>
            </Col>
            {/* {pdf && (
              <Col className={"text-right"}>
                <Card.Text>
                  <Button
                    onClick={() => modalPopUp(true)}
                    variant="preview"
                    icon={`search-plus`}
                    label="Preview"
                  />
                </Card.Text>
              </Col>
            )} */}
          </Row>
        </Container>
        <Card.Title className="p-3 mb-0">{title}</Card.Title>
        <Card.Text className={"px-3 caption"}>{caption}</Card.Text>
        <Card.Text className={"px-3 pb-3 external-link"}>
          <FontAwesomeIcon
            className={"mr-2"}
            icon={field_vimeo_id ? "arrow-right" : "arrow-down"}
          />
          {label}
        </Card.Text>
      </a>
    </>
  )

  if (isFeatured && imageFeatured) {
    return (
      <>
        <Card
          className={"featured alt d-none d-md-block"}
          data-sal="slide-up"
          data-sal-duration="1200"
          data-sal-delay="1200"
          data-sal-easing="ease"
        >
          <Popup
            show={modalShow}
            onHide={() => modalPopUp(false)}
            title={title}
            video={video}
            pdf={(link && link.uri) || null}
          />
          <Card.Link {...args}>
            <Img
              key={
                "img-featured-" +
                imageFeatured?.childImageSharp?.fixed?.originalName
              }
              loading={"lazy"}
              fixed={imageFeatured?.childImageSharp?.fixed}
              alt={imageAlt}
            />
            <Card.ImgOverlay>
              <Col md={6} className={"position-absolute pb-0 pb-lg-3"}>
                <Card.Title>{title}</Card.Title>
                <Card.Text className={"caption"}>{caption}</Card.Text>
                <p className={"card-link mb-0 pb-lg-3"}>
                  <FontAwesomeIcon icon={"arrow-down"} />
                  {label}
                </p>
              </Col>
            </Card.ImgOverlay>
          </Card.Link>
        </Card>
        <TiledCard classes={classes + " d-md-none"} />
      </>
    )
  } else {
    return <TiledCard classes={classes} />
  }
}
